<template>
  <a-modal
    title="新建客户"
    :width="580"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="客户">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'client_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入客户"
                :default-active-first-option="false"
                @search="this.clientSearch"
                @change="handleClientChange"
              >
                <a-select-option v-for="d in clientList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import debounce from 'lodash/debounce'
import { client_list } from '@/api/trade_client'

// 表单字段
const fields = ['id', 'name', 'code']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.clientSearch = debounce(this.handleClientSearch, 800)
    return {
      form: this.$form.createForm(this),
      clientList: []
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.handleClientSearch(undefined)
  },
  methods: {
    handleClientSearch (value) {
      client_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.clientList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleClientChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleClientSearch(value)
      }
    }
  }
}
</script>
